<template>
  <div>
    <div class="flex justify-between pb-1" v-if="label">
      <label class="block text-sm font-medium leading-5 text-gray-700">{{
        label
      }}</label>
      <span class="text-sm leading-5 text-gray-500" v-if="required">
        {{ $t ? $t('sqrd_ui_required') : 'Required' }}
      </span>
    </div>
    <div class="input-radio" :class="inputClass">
      <div :class="boxClass">
        <div
          class="radio-row"
          v-for="opt in options"
          :key="opt.id"
          :class="itemClass"
        >
          <input
            :id="opt.id"
            :name="name"
            type="radio"
            class="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
            :value="opt.id"
            v-model="option"
            :disabled="disabled"
          />
          <label :for="opt.id" class="ml-3">
            <span
              class="block text-sm leading-5 font-normal"
              :class="disabled ? 'text-gray-400' : 'text-gray-700'"
              >{{ opt.label }}</span
            >
          </label>
        </div>
      </div>
    </div>
    <slot name="invalid" v-if="v && v.$error && !v.required">
      <p class="mt-1 text-xs text-red-600">{{ label }} is required.</p>
    </slot>
  </div>
</template>

<style lang="postcss" scoped>
.input-radio.invalid {
  @apply border-red-300 bg-red-50 rounded text-red-900 p-2 -mx-2 border;
}
.radio-row {
  @apply flex items-center;
}
</style>

<script>
import SqrInput from './SqrInput';
export default {
  name: 'SqrdInputRadio',
  mixins: [SqrInput],
  model: { input: 'value', event: 'selected' },
  props: {
    name: String,
    value: { type: [String, Number] },
    options: Array,
    disabled: Boolean,
    boxClass: { type: [Array, String, Object] },
    itemClass: { type: [Array, String, Object] },
  },
  computed: {
    option: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('selected', value);
        this.$emit('change', value);
      },
    },
  },
};
</script>
